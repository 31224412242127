import React, { ComponentType } from "react";

import LoginQueryClientProvider from "./login/providers/LoginQueryClientProvider";

import { react2angular } from "react18-react2angular";

/**
 * A higher-order function that wraps a React component with a specified container
 * and converts it to an AngularJS component using the `react2angular` library.
 *
 * @param {ComponentType} Component - The React component to be wrapped and converted.
 * @param {(keyof T)[] | null} [bindings] - An optional array of binding keys to be passed to the AngularJS component.
 * @param {string[]} [injectNames] - An optional array of services or dependencies injected into the Angular wrapper.
 * @returns {Function} - A function that takes props and returns the wrapped React component.
 */
const react2angularWrapper = <T extends {}>(
    Component: ComponentType,
    bindings?: (keyof T)[] | null,
    injectNames?: string[]
): angular.IComponentOptions => {
    return react2angular(
        (props) => {
            return (
                <LoginQueryClientProvider>
                    <Component {...props} />
                </LoginQueryClientProvider>
            );
        },
        bindings,
        injectNames
    );
};

export default react2angularWrapper;
